<template>
  <v-container class="payment-completed" v-if="!loading">
    <div class="checkout-title">
      <CategoryTitle
        :category="category"
        :showOnMobile="true"
        :centered="$vuetify.breakpoint.xs"
        :addContainer="false"
      />
    </div>
    <div>
      <v-card class="h-100" color="transparent" elevation="0">
        <v-card-title v-if="order && paymentFailed">
          {{ $t("paymentCompleted.failed") }}
        </v-card-title>
        <v-card-text v-if="order && paymentFailed" class="px-0 mt-3 mb-6">
          <p
            class="payment-failed-desc"
            v-html="$t('paymentCompleted.failedDesc')"
          />

          <p
            class="repay"
            v-if="order.isPayable"
            v-html="$t('order.repay', [order.orderId])"
          ></p>
        </v-card-text>

        <template v-if="order && !paymentFailed">
          <v-card-title>
            <span v-html="$t('paymentCompleted.success')"></span>
          </v-card-title>
          <v-card-text>
            <div class="px-0">
              <!-- PICKUP -->
              <template
                v-if="!order.isPayable && !order.homeDelivery && !easyDrive"
              >
                <p
                  class="note"
                  v-html="$t('paymentCompleted.detail.pickup.note')"
                />
                <router-link
                  :aria-label="order.orderId"
                  :title="order.orderId"
                  class="order-id d-block default--text pa-4 mb-8 mt-6"
                  :to="{ name: 'Order', params: { orderId: order.orderId } }"
                >
                  {{ order.orderId }}
                </router-link>
                <i18n
                  path="paymentCompleted.detail.pickup.reminder"
                  tag="p"
                  class="reminder"
                >
                  <template v-slot:ordersLink>
                    <router-link
                      :aria-label="$t('paymentCompleted.detail.pickup.note')"
                      :title="$t('paymentCompleted.detail.pickup.note')"
                      class="text-decoration-none"
                      :to="{ name: 'Orders' }"
                    >
                      {{
                        $t("paymentCompleted.detail.pickup.reminderOrderLink")
                      }}
                    </router-link>
                  </template>
                </i18n>
                <p
                  class="where"
                  v-html="
                    $t('paymentCompleted.detail.pickup.where', [
                      order.shippingAddress.addressName
                    ])
                  "
                />
              </template>

              <!-- HOME -->
              <div v-if="!order.isPayable && order.homeDelivery">
                <p
                  class="note"
                  v-html="$t('paymentCompleted.detail.home.note')"
                />
                <router-link
                  :aria-label="order.orderId"
                  :title="order.orderId"
                  class="order-id d-block default--text pa-4 mb-4 "
                  :to="{ name: 'Order', params: { orderId: order.orderId } }"
                >
                  {{ order.orderId }}
                </router-link>
                <i18n
                  path="paymentCompleted.detail.pickup.reminder"
                  tag="p"
                  class="reminder"
                >
                  <template v-slot:ordersLink>
                    <router-link
                      :aria-label="$t('paymentCompleted.detail.pickup.note')"
                      :title="$t('paymentCompleted.detail.pickup.note')"
                      class="text-decoration-none"
                      :to="{ name: 'Orders' }"
                    >
                      {{
                        $t("paymentCompleted.detail.pickup.reminderOrderLink")
                      }}
                    </router-link>
                  </template>
                </i18n>
                <p
                  class="where"
                  v-html="
                    $t('paymentCompleted.detail.home.where', [
                      order.shippingAddress.address1,
                      order.shippingAddress.city
                    ])
                  "
                />
              </div>
            </div>

            <v-divider class="my-5" />
            <i18n
              path="paymentCompleted.pending"
              tag="p"
              class="pending font-weight-bold"
              v-if="paymentPending"
            >
              <template v-slot:link>
                <router-link
                  :aria-label="order.orderId"
                  :title="order.orderId"
                  class="text-decoration-none"
                  :to="{
                    name: 'Order',
                    params: { orderId: order.orderId }
                  }"
                >
                  {{ $t("paymentCompleted.ordersLink") }}
                </router-link>
              </template>
            </i18n>
            <p
              class="info-2 font-weight-bold"
              v-if="order.orderStatusId == 1"
              v-html="$t('order.info2', [order.orderId])"
            ></p>
            <p
              class="repay"
              v-if="order.isPayable"
              v-html="$t('order.repay', [order.orderId])"
            ></p>
            <p
              class="info-3 font-weight-bold"
              v-if="order.orderStatusId == 11"
              v-html="$t('order.info3', [order.orderId])"
            ></p>
          </v-card-text>
        </template>

        <v-card-title v-if="!order">
          <span v-html="$t('paymentCompleted.successNoOrder')"></span>
        </v-card-title>

        <v-card-actions
          class="flex-column flex-sm-row justify-space-between gap-2"
        >
          <v-btn
            :to="{ name: 'Home', path: '/' }"
            large
            min-width="200"
            depressed
            color="primary"
            outlined
            :block="$vuetify.breakpoint.xs"
            >{{ $t("paymentCompleted.goToHome") }}</v-btn
          >
          <template v-if="paymentFailed && order.isPayable">
            <v-btn
              v-if="!showPayment"
              large
              min-width="200"
              class="mx-0"
              depressed
              color="primary"
              :block="$vuetify.breakpoint.xs"
              @click="toggleShowPayment"
            >
              {{ $t("paymentCompleted.retry") }}
            </v-btn>
          </template>

          <v-btn
            :to="{ name: 'Order', params: { orderId: order.orderId } }"
            large
            min-width="200"
            class="mx-0"
            depressed
            color="primary"
            :block="$vuetify.breakpoint.xs"
          >
            {{ $t("paymentCompleted.goToDetail") }}
          </v-btn>
        </v-card-actions>

        <div
          class="payment-type-list-container pa-4 grey lighten-1 mt-5 rounded-md"
          v-if="showPayment"
        >
          <PaymentTypeList
            mode="order"
            :order-id="order.orderId"
            :options="paymentTypeListOptions"
          />
        </div>
      </v-card>
    </div>
  </v-container>
</template>
<style lang="scss">
.payment-completed {
  .v-card {
    .v-card__title {
      font-size: 2rem;
      font-weight: 500;
    }
    .v-card__title,
    .v-card__text {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .order-id {
    width: fit-content;
    font-size: 2rem;
    font-weight: 600;
    border: 1px solid var(--v-default-base);
    text-decoration: none;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import categoryMixins from "~/mixins/category";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: {
    PaymentTypeList,
    CategoryTitle
  },
  data() {
    return {
      loading: true,
      order: null,
      showPayment: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "secondary--text text--darken-2 text-body-1 mb-2",
        mainBackgroundColor: "transparent",
        confirm: { color: "primary" },
        back: {
          outline: true,
          class: "secondary--text"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    paymentFailed() {
      return this.order?.orderStatusId == 11;
    },
    paymentPending() {
      return this.order?.orderStatusId == 2;
    },
    detailDate() {
      return this.$dayjs(this.order.timeslot.date).format("DD MMMM");
    },
    detailTimeslot() {
      let from = this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm");
      let to = this.$dayjs(this.order.timeslot.endTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm");
      if (!this.order.homeDelivery) {
        return `potrai ritirarlo dalle <b>${from}</b> alle
          <b>${to}</b>`;
      }
      return `ti verrà consegnato dalle <b>${from}</b> alle
          <b>${to}</b>`;
    },
    easyDrive() {
      return (
        this.order.warehouse.params != undefined &&
        this.order.warehouse.params.EASYDRIVE === "1"
      );
    },
    shippingAddress() {
      return `${this.order.shippingAddress.addressName} (${this.order.shippingAddress.address1}
            ${this.order.shippingAddress.addressNumber}, ${this.order.shippingAddress.city})`;
    }
  },
  methods: {
    ...mapActions({
      addOrderToAnalytics: "app/addOrderSentToAnalytics",
      updateOrderToPay: "custom/updateOrderToPay"
    }),
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    },
    async fetchOrder(orderId) {
      let _this = this;
      _this.order = await OrderService.getOrder(orderId);
      if (_this.order.orderStatusId == 3) {
        if (!this.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(_this.order);
            this.addOrderToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
      _this.loading = false;
    }
  },
  mounted() {
    this.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      this.showPayment = false;
      this.fetchOrder(order.orderId);
    });
    this.updateOrderToPay();
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
